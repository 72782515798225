<template>
   <div class="productName">
      <div v-show="addPage">
         <div ref="search" class="search">
            <el-card class="box-card" shadow="never" :body-style="{ padding: '10px' }">
               <el-form :inline="true" :model="searchForm" label-width="80px" ref="searchForm">
                  <search-open-btn :searchShow="searchShow" @searchOpen="searchOpen"></search-open-btn>
                  <el-form-item label="制造商">
                     <el-select v-model="searchForm.goodsPiCustomerId" :size="$store.state.size" placeholder="制造商" clearable>
                        <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId" @click.native="changeCustomerType(e.sysCiId)"></el-option>
                     </el-select>
                  </el-form-item>

                  <el-form-item label="品牌">
                     <el-select :disabled='!searchForm.goodsPiCustomerId' v-model="searchForm.goodsPiBrandId" :size="$store.state.size" placeholder="品牌" clearable filterable>
                        <el-option v-for="e in brandList" :key="e.goodsBiId" :label="e.goodsBiName" :value="e.goodsBiId"></el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="品种名称">
                     <assembly-autocomplete :disabled='!searchForm.goodsPiBrandId' ref="assemblyAutocomplete" @change="searchForm.goodsPiName = $event" placeholder="品种名称" :clearable="true" @keyup.enter.native="searchSubmit(true)">
                     </assembly-autocomplete>
                     <!--            <el-input v-model="searchForm.goodsPiName" :size="$store.state.size" placeholder="品种名称" clearable></el-input>-->
                  </el-form-item>
                  <el-form-item label="商品类型">
                     <el-select v-model="searchForm.goodsPiCategoryId" :size="$store.state.size" placeholder="商品类型" clearable>
                        <el-option v-for="(item, index) in categoryList" :key="index" :label="item.goodsPcName" :value="item.goodsPcId"></el-option>
                     </el-select>
                  </el-form-item>

                  <!--            <el-form-item label="商品类型" prop="goodsPiCategoryId">-->
                  <!--              <el-radio-group  v-model="saveForm.goodsPiCategoryId" @change="changeIsLens()">-->
                  <!--                <el-radio v-for="item in categoryList" :key="item.goodsPcId" :label="item.goodsPcId">{{item.goodsPcName}}</el-radio>-->
                  <!--              </el-radio-group>-->
                  <!--            </el-form-item>-->

                  <el-form-item label="SKU" v-show="searchShow">
                     <el-input @keyup.enter.native="searchSubmit(true)" v-model="searchForm.goodsPiSku" :size="$store.state.size" placeholder="SKU" clearable></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="加工参数" v-show="searchShow">
                <el-select v-model="searchForm.paramId" :size="$store.state.size" placeholder="加工参数" clearable @click.native="machiningInfo('searchForm')">
                  <el-option v-for="e in machininList" :key="e.paramMiId" :label="e.paramMiName"
                             :value="e.paramMiId"></el-option>
                </el-select>
              </el-form-item> -->

                  <el-form-item label="商品归属">
                     <el-select v-model="searchForm.goodsPiIsAgentCode" :size="$store.state.size" placeholder="商品类型" clearable @change="goodsPII">
                        <el-option v-for="(item, index) in goodsGs" :key="index" :label="item.label" :value="item.value"></el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="供应商" v-if="searchForm.goodsPiIsAgentCode=='0'">
                     <el-select v-model="searchForm.supplierIdList" :size="$store.state.size" placeholder="供应商" clearable multiple @change="mulsupplierIdList">
                        <el-option v-for="(item, index) in manufacturerListdata" :key="index" :label="item.sysCiShortName" :value="item.sysCiId"></el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="启用状态" v-show="searchShow">
                     <el-select v-model="searchForm.goodsPiStatus" :size="$store.state.size" placeholder="启用状态" clearable>
                        <el-option label="启用" :value="0"></el-option>
                        <el-option label="停用" :value="1"></el-option>
                     </el-select>
                  </el-form-item>
               </el-form>
               <el-divider></el-divider>
               <el-button v-if="hasPermission('3-1-a')" type="primary" :size="$store.state.size" @click="searchSubmit(true)" icon="el-icon-search">查询
               </el-button>
               <el-button v-if="hasPermission('3-1-b')" type="default" plain :size="$store.state.size" @click="resetSubmit" icon="el-icon-delete">清空
               </el-button>
               <el-button v-if="hasPermission('3-1-c')" type="primary" :size="$store.state.size" @click="saveOpen" icon="el-icon-circle-plus-outline">新增
               </el-button>
               <!-- <el-button
            v-if="hasPermission('3-1-g')"
            type="primary"
            :size="$store.state.size"
            @click="importOpen"
            icon="el-icon-upload el-icon--right"
            >导入
          </el-button> -->
            </el-card>
         </div>

         <el-table :data="tableData" :height="tableHeight" v-loading="tableLoading" border @sort-change="sortChange" :size="this.$common.tableLineHeight()" :header-cell-style="{ background: '#f5f7fa', color: '#515a6e', fontWeight: '900' }" style="width: 100%; margin-top: 10px">
            <el-table-column prop="customer" align="center" label="所属制造商"> </el-table-column>
            <el-table-column prop="brandName" align="center" label="所属品牌"> </el-table-column>
            <el-table-column prop="goodsPiName" align="center" label="品种名称"> </el-table-column>
            <el-table-column align="center" label="商品类型">
               <template slot-scope="scope">
                  <!-- {{ scope.row.goodsPiIsLens === 0 ? "是" : "否" }} -->
                  {{ _setGoodsType(scope.row.goodsPiCategoryId) }}
               </template>
            </el-table-column>
            <!-- <el-table-column align="center" label="是否定制片" width="90">
          <template slot-scope="scope">
            {{ scope.row.goodsPiIsCustomized === 0 ? "是" : scope.row.goodsPiIsCustomized === 1 ? "否" : "" }}
            {{ _setDZP(scope.row) }}
          </template>
        </el-table-column> -->
            <el-table-column prop="goodsPiSku" align="center" label="标准SKU">
               <template slot-scope="scope">
                  {{ scope.row.goodsPiSku === "" ? "--" : scope.row.goodsPiSku }}
               </template>
            </el-table-column>
            <el-table-column prop="goodsPiIsAgentCode" align="center" label="供应商编码">
               <template slot-scope="scope">
                  {{ scope.row.goodsPiIsAgentCode === "0" ? "是" : "否" }}
               </template>
            </el-table-column>
            <!-- <el-table-column prop="supplierName" align="center" label="供应商">
          <template slot-scope="scope">
            {{ scope.row.supplierName }}
          </template>
        </el-table-column> -->
            <!-- <el-table-column align="center" label="膜层" width="130" :formatter="formatmapList"> </el-table-column> -->
            <!-- <el-table-column
            align="center"
            label="加工参数">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark"
                        :content="scope.row.paramList?scope.row.paramList.map(obj => { return obj.paramMiName; }).toString():''"
                        placement="top">
              <div class="nowrap">
                <el-tag style="margin:2px 5px" :key="index" v-for="(item, index) of scope.row.paramList">
                  {{ item.paramMiName }}
                </el-tag>
              </div>
            </el-tooltip>
          </template>
        </el-table-column> -->
            <el-table-column align="center" label="启用状态" width="150">
               <template slot-scope="scope">
                  <el-switch v-model="scope.row.goodsPiStatus" :active-value="0" :inactive-value="1" active-text="启用" inactive-text="停用" @change="stopBtn(scope.row)">
                  </el-switch>
               </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="190">
               <template slot-scope="scope">
                  <el-button v-if="hasPermission('3-1-d')" type="text" size="small" @click="seeOpen(scope.row)" icon="el-icon-document">查看
                  </el-button>
                  <el-button v-if="hasPermission('3-1-e')" type="text" size="small" @click="editOpen(scope.row)" icon="el-icon-edit-outline">编辑
                  </el-button>
                  <el-button v-if="hasPermission('3-1-f')" type="text" size="small" @click="delOpen(scope.row)" icon="el-icon-delete">删除
                  </el-button>
               </template>
            </el-table-column>
         </el-table>

         <pagination :page-size='currentPageSize' :current-page="currentPage" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
         </pagination>

         <!--    查看模态框-->
         <assembly-dialog title="查看" :visible.sync="dialogVisibleSee" width="30%" top="8vh" @before-close="seeClose">
            <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border>
               <el-descriptions-item>
                  <template slot="label">
                     制造商
                  </template>
                  {{ seeForm.customer }}
               </el-descriptions-item>
               <el-descriptions-item>
                  <template slot="label">
                     品牌
                  </template>
                  {{ seeForm.brandName }}
               </el-descriptions-item>
               <el-descriptions-item>
                  <template slot="label">
                     品种名称
                  </template>
                  {{ seeForm.goodsPiName }}
               </el-descriptions-item>
               <el-descriptions-item v-if="isShow">
                  <template slot="label">
                     品种编码
                  </template>
                  {{ seeForm.goodsPiCode }}
               </el-descriptions-item>
               <el-descriptions-item>
                  <template slot="label">
                     是否框架镜片
                  </template>
                  {{ seeForm.goodsPiIsLens === 0 ? "是" : "否" }}
               </el-descriptions-item>
               <el-descriptions-item v-if="seeForm.goodsPiIsLens === 0">
                  <template slot="label">
                     SKU
                  </template>
                  {{ seeForm.goodsPiSku }}
               </el-descriptions-item>
               <el-descriptions-item>
                  <template slot="label">
                     启停状态
                  </template>
                  {{ seeForm.goodsPiStatus === 0 ? "启用" : "停用" }}
               </el-descriptions-item>
               <el-descriptions-item v-if="seeForm.goodsPiIsLens === 0">
                  <template slot="label">
                     定制片
                  </template>
                  {{ seeForm.goodsPiIsCustomized === 0 ? "是" : "否" }}
               </el-descriptions-item>
               <!-- <el-descriptions-item v-show="seeForm.goodsPiIsLens === 0" v-for="(e, key) of seeForm.section" :key="key">
            <template slot="label">
              球/区间/柱/区间/ADD/区间/直径/区间
            </template>
            {{
              e.goodsPsSphStart +
                "~" +
                e.goodsPsSphEnd +
                "/" +
                e.goodsPsSphSpan +
                "/" +
                e.goodsPsCylStart +
                "~" +
                e.goodsPsCylEnd +
                "/" +
                e.goodsPsCylSpan +
                "/" +
                (e.goodsPsAddStart ? e.goodsPsAddStart : "") +
                "~" +
                e.goodsPsAddEnd +
                "/" +
                e.goodsPsAddSpan +
                "/" +
                e.goodsPsDiameterStart +
                "~" +
                e.goodsPsDiameterEnd +
                "/" +
                e.goodsPsDiameterSpan
            }}
          </el-descriptions-item> -->
               <!-- <el-descriptions-item v-if="seeForm.goodsPiIsLens === 0">
            <template slot="label">
              折射率
            </template>
            {{ returnName(seeForm.mapList, seeForm.goodsPiRef) }}
          </el-descriptions-item>
          <el-descriptions-item v-if="seeForm.goodsPiIsLens === 0">
            <template slot="label">
              材料分类
            </template>
            {{ returnName(seeForm.mapList, seeForm.goodsPiMaterial) }}
          </el-descriptions-item>
          <el-descriptions-item v-if="seeForm.goodsPiIsLens === 0">
            <template slot="label">
              镜片功能
            </template>
            {{ returnName(seeForm.mapList, seeForm.goodsPiFunction) }}
          </el-descriptions-item>
          <el-descriptions-item v-if="seeForm.goodsPiIsLens === 0">
            <template slot="label">
              光度分类
            </template>
            {{ returnName(seeForm.mapList, seeForm.goodsPiLuminosity) }}
          </el-descriptions-item> -->
            </el-descriptions>

            <span slot="footer" class="dialog-footer">
               <el-button :size="$store.state.size" @click="seeClose">关 闭</el-button>
            </span>
         </assembly-dialog>
      </div>

      <!--新增页面-->
      <transition name="el-fade-in">
         <div class="productName">
            <assembly-card-page :title="dialogTitle" v-show="!addPage" @back="back">
               <!-- <assemblyTitle title="基础信息"></assemblyTitle> -->
               <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="100px">
                  <div class="flex_box_form">
                     <el-form-item label="制造商" prop="goodsPiCustomerId">
                        <el-select v-model="saveForm.goodsPiCustomerId" :size="$store.state.size" placeholder="制造商" clearable @change="clear1()" :disabled="dialogStatus">
                           <el-option v-for="(e, index) in manufacturerList" :key="index" :label="e.sysCiShortName" :value="e.sysCiId" @click.native="changeCustomerType(e.sysCiId)" :data-code="e.sysCiCode"></el-option>
                        </el-select>
                     </el-form-item>
                     <el-form-item label="品牌" prop="goodsPiBrandId">
                        <el-select v-model="saveForm.goodsPiBrandId" :size="$store.state.size" placeholder="品牌" clearable @change="clear1()" :disabled="dialogStatus" filterable>
                           <el-option v-for="(e, index) in saveBrandList" :key="index" :label="e.goodsBiName" :value="e.goodsBiId" @click.native="productDroplistByGroup(e.goodsBiId)" :data-code="e.goodsBiId"></el-option>
                        </el-select>
                     </el-form-item>
                     <el-form-item label="品种名称" prop="goodsPiName">
                        <el-input style="width:205px" v-model="saveForm.goodsPiName" :size="$store.state.size" placeholder="品种名称" clearable></el-input>
                     </el-form-item>
                  </div>
                  <el-form-item label="商品类型" prop="goodsPiCategoryId">
                     <el-radio-group v-model="saveForm.goodsPiCategoryId" @change="changeIsLens()">
                        <el-radio v-for="item in categoryList" :key="item.goodsPcId" :label="item.goodsPcId">{{ item.goodsPcName }}</el-radio>
                     </el-radio-group>
                  </el-form-item>

                  <!-- <div class="flex_box_form" v-if="saveForm.goodsPiCategoryId != 3">
              <el-form-item label="型号" prop="goodsPiSku">
                <el-input style="width:205px" v-model="saveForm.goodsPiSku" :size="$store.state.size" placeholder="SKU" clearable></el-input>
              </el-form-item>

              <el-form-item label="色号" prop="goodsPiSku">
                <el-input style="width:205px" v-model="saveForm.goodsPiSku" :size="$store.state.size" placeholder="SKU" clearable></el-input>
              </el-form-item>
              <el-form-item label="度数" prop="goodsPiSku" v-if="saveForm.goodsPiCategoryId == 8">
                <el-input style="width:205px" v-model="saveForm.goodsPiSku" :size="$store.state.size" placeholder="SKU" clearable></el-input>
              </el-form-item>
            </div> -->

                  <el-form-item label="编码类型" prop="goodsPiIsAgentCode">
                     <el-radio v-model="saveForm.goodsPiIsAgentCode" :label="'1'" @change="changeProductCodeType('1')">厂家原码</el-radio>
                     <el-radio v-model="saveForm.goodsPiIsAgentCode" :label="'0'" @change="changeProductCodeType('0')">供应商编码</el-radio>
                  </el-form-item>

                  <el-form-item label="SKU" prop="goodsPiSku" v-if="saveForm.goodsPiIsAgentCode == 1">
                     <el-input style="width:500px" v-model="saveForm.goodsPiSku" :size="$store.state.size" placeholder="SKU" clearable></el-input>
                  </el-form-item>

                  <!-- 野马的框 -->
                  <el-form-item label="供应商编码" prop="goodsPiSupplierId" v-if="saveForm.goodsPiIsAgentCode == 0">
                     <el-select v-model="saveForm.goodsPiSupplierId" placeholder="选择供应商" style="width:200px;margin-right:5px">
                        <el-option v-for="item in supplierList" :key="item.sysCiId" :label="item.sysCiShortName" :value="item.sysCiId"> </el-option>
                     </el-select>
                     <!-- 之前---saveForm。goodsPiAgentCode 已弃用-->
                     <el-input style="width:295px" v-model="saveForm.goodsPiSku" :size="$store.state.size" placeholder="SKU" clearable></el-input>
                  </el-form-item>

                  <el-form-item label="定制片" prop="goodsPiIsCustomized" v-if="saveForm.goodsPiCategoryId == 3">
                     <el-radio v-model="saveForm.goodsPiIsCustomized" :label="0">是</el-radio>
                     <el-radio v-model="saveForm.goodsPiIsCustomized" :label="1">否</el-radio>
                  </el-form-item>
                  <el-form-item label="启用状态" prop="goodsPiStatus">
                     <el-radio v-model="saveForm.goodsPiStatus" :label="0" @change="changeStatus()">启用</el-radio>
                     <el-radio v-model="saveForm.goodsPiStatus" :label="1" @change="changeStatus()">停用</el-radio>
                  </el-form-item>
               </el-form>
               <div class="footbtn">
                  <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
                  <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
               </div>
            </assembly-card-page>
         </div>
      </transition>
      <!--  导入模态框  -->
      <assembly-dialog title="导入" :visible.sync="dialogVisibleImport" width="30%" destroy-on-close @before-close="importClose">
         <el-form :model="importForm" :rules="importRules" ref="importForm" label-width="100px">
            <el-form-item label="制造商" prop="customerId">
               <el-select v-model="importForm.customerId" :size="$store.state.size" placeholder="制造商" clearable>
                  <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="Excel文件" prop="multipartFile">
               <el-upload class="upload-demo" ref="upload" accept=".xlsx" action="/api/goods/product-info/productImport" limit="1" :headers="{
              Authorization: Authorization,
            }" name="multipartFile" :file-list="importForm.fileList" :on-change="
              file => {
                importForm.multipartFile = file
              }
            " :on-success="importFormSuccess" :data="{
              customerId: importForm.customerId,
            }" :auto-upload="false">
                  <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                  <div slot="tip" class="el-upload__tip" style="display: inline-block;margin-left: 20px;color: red">
                     * 只能上传xlsx格式的 excel文件
                  </div>
               </el-upload>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearForm('importForm')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="importSubmit('importForm')">确 定</el-button>
         </span>
      </assembly-dialog>
      <!--  选择供应商模态框  -->
      <assembly-dialog title="选择供应商" :visible.sync="seachAgentSwitch" @before-close="outClose" width="30%">
         <el-form label-width="80px">
            <el-form-item label="供应商" prop="goodsPiSupplierId" label-width="100px">
               <el-select v-model="saveForm.goodsPiSupplierId" :size="$store.state.size" placeholder="供应商" clearable>
                  <el-option v-for="e in supplierList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId" />
               </el-select>
            </el-form-item>
            <el-form-item label="供应商编码" prop="goodsPiAgentCode" label-width="100px">
               <el-input class="width50" v-model="saveForm.goodsPiAgentCode" :size="$store.state.size" placeholder="SKU" clearable></el-input>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearSupplier()">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="saveSupplier()">确 定</el-button>
         </span>
      </assembly-dialog>
   </div>
</template>

<script>
import assemblyCardPage from "@/components/assembly-card-page"
import searchOpenBtn from "@/components/assembly-search/search-open-btn"
import pagination from "@/components/assembly-pagination/index"
import AssemblyAutocomplete from "@/components/assembly-autocomplete"
import AssemblyDialog from "components/assembly-dialog";

export default {
   name: "productName",
   components: {
      AssemblyDialog,
      assemblyCardPage,
      AssemblyAutocomplete,
      searchOpenBtn,
      pagination,
   },
   watch: {
      //监听头部展开收起变化
      "$store.state.isHeader"() {
         this.setTableHeight()
      },
      //监听快速导航展开收起变化
      "$store.state.fastNav"() {
         this.setTableHeight()
      },
      //监听搜索区域变化改变列表
      searchShow() {
         this.setTableHeight()
      },
      //监听制造商下拉框变化
      "searchForm.goodsPiCustomerId"(val) {
         if (val) {
            if (this.searchForm.goodsPiCustomerId) {
               // this.goodsPiNameChange()
            } else {
               this.goodsPiNameList = []
               this.$refs.assemblyAutocomplete.setList(this.goodsPiNameList)
            }
         } else {
            this.$set(this.searchForm, "goodsPiBrandId", "")
            this.brandList = []
         }
      },
      //监听品牌下拉框变化
      "searchForm.goodsPiBrandId"(val) {
         if (val) {
            if (this.searchForm.goodsPiBrandId) {
               this.goodsPiNameChange()
            } else {
               this.goodsPiNameList = []
               this.$refs.assemblyAutocomplete.setList(this.goodsPiNameList)
            }
         } else {
            this.$refs.assemblyAutocomplete.state = ""
            this.$set(this.searchForm, "goodsPiName", "")
            this.goodsPiNameList = []
         }
      },
      //监听品名内容变化搜索对应品名列表
      "searchForm.goodsPiName"() {
         if (this.searchForm.goodsPiName || this.searchForm.goodsPiBrandId) {
            this.goodsPiNameChange()
         } else {
            this.goodsPiNameList = []
            this.$refs.assemblyAutocomplete.setList(this.goodsPiNameList)
         }
      },
   },
   data() {
      let validatorSKU = (rule, value, callback) => {
         if (!this.saveForm.goodsPiSku || !this.saveForm.goodsPiSupplierId) {
            callback(new Error())
         } else {
            callback()
         }
      }
      return {
         currentPageSize: 10,
         addTitle: '新增',
         Authorization: sessionStorage.getItem("Authorization") === null ? "" : sessionStorage.getItem("Authorization"),
         dialogTitle: "新增",
         //搜索区域展开收起开关
         searchShow: false,
         //搜索区域展开收起图标
         searchOpenIcon: "el-icon-arrow-down",
         //加载
         tableLoading: false,
         //表格高度
         tableHeight: 0,
         //表格数据
         tableData: [],
         //当前页面
         currentPage: 1,
         //总数
         total: 0,
         //搜索表单
         searchForm: {
            page: 1,
            pageSize: this.$store.state.initPageSize,
            goodsPiCustomerId: "",
            goodsPiBrandId: "",
            goodsPiName: "",
            goodsPiIsLens: "",
            goodsPiSku: "",
            paramId: "",
            sysPiThemeColour: "",
            goodsPiStatus: "",
            userid: "",
            goodsPiCategoryId: "",
            goodsPiIsAgentCode: "0",
            supplierIdList: []
         },
         //保存模态框开关
         dialogVisibleSave: false,
         //选择供应商模态框开关
         seachAgentSwitch: false,
         //是否是修改模态窗
         dialogStatus: true,
         addPage: true,
         //隐藏模块
         isShow: false,
         //导入模态框开关
         dialogVisibleImport: false,
         //导入表单
         importForm: {
            customerId: "",
            multipartFile: [],
            //上传文件组件列表回显
            fileList: [],
         },
         //保存表单
         saveForm: {
            goodsPiId: "",
            goodsPiCustomerId: "",
            goodsPiName: "",
            goodsPiIsLens: "",
            goodsPiSku: "",
            paramIds: [],
            goodsPiStatus: 0,
            //goodsPiCode: '',
            goodsPiIsCustomized: "",
            goodsPiLuminosity: "",
            goodsPiMaterial: "",
            goodsPiRef: "",
            goodsPiFunction: "",
            goodsPiJuse: "",
            goodsPiBrandId: "",
            goodsPiIsAgentCode: "0",
            // section: [
            //   {
            //     goodsPsId: "",
            //     goodsPsSphStart: "",
            //     goodsPsSphEnd: "",
            //     goodsPsSphSpan: "",
            //     goodsPsCylStart: "",
            //     goodsPsCylEnd: "",
            //     goodsPsCylSpan: "",
            //     goodsPsAddStart: "",
            //     goodsPsAddEnd: "",
            //     goodsPsAddSpan: "",
            //     goodsPsDiameterStart: "",
            //     goodsPsDiameterEnd: "",
            //     goodsPsDiameterSpan: "",
            //   },
            // ],
            productCodeType: 0,
            goodsPiSupplierId: "",
            goodsPiAgentCode: "",
            goodsPiCategoryId: "",
            isSave: 1, //编辑查询传1，查询传0
            isAdd: false

         },
         //保存表单验证
         saveRules: {
            goodsPiSupplierId: [{ required: true, message: "请选择供应商并输入SKU", trigger: "change", validator: validatorSKU }],
            goodsPsSphStart: [{ required: true, message: "请选择球镜", trigger: "change" }],
            goodsPsSphEnd: [{ required: true, message: "请选择球镜", trigger: "change" }],
            goodsPsSphSpan: [{ required: true, message: "请选择球镜间隔", trigger: "change" }],
            goodsPsCylStart: [{ required: true, message: "请选择柱镜", trigger: "change" }],
            goodsPsCylEnd: [{ required: true, message: "请选择柱镜", trigger: "change" }],
            goodsPsCylSpan: [{ required: true, message: "请选择柱镜间隔", trigger: "change" }],
            goodsPsDiameterStart: [{ required: true, message: "请选择直径", trigger: "change" }],
            goodsPsDiameterEnd: [{ required: true, message: "请选择直径", trigger: "change" }],
            goodsPsDiameterSpan: [{ required: true, message: "请选择直径间隔", trigger: "change" }],
            goodsPsAddStart: [{ required: true, message: "请选择ADD", trigger: "change" }],
            goodsPsAddEnd: [{ required: true, message: "请选择ADD", trigger: "change" }],
            goodsPsAddSpan: [{ required: true, message: "请选择ADD间隔", trigger: "change" }],
            goodsPiCustomerId: [{ required: true, message: "请选择所属制造商", trigger: "change" }],
            goodsPiBrandId: [{ required: true, message: "请选择所属品牌", trigger: "change" }],
            goodsPiName: [{ required: true, message: "请输入品种名称", trigger: "blur" }],
            goodsPiCategoryId: [{ required: true, message: "商品类型", trigger: "change" }],
            goodsPiIsCustomized: [{ required: true, message: "请选择是否定制片", trigger: "change" }],
            productCodeType: [{ required: true, message: "请选择编码类型", trigger: "change" }],
            goodsPiSku: [{ required: true, message: "请输入SKU", trigger: "blur" }],
            paramIds: [{ required: true, message: "请选择加工参数", trigger: "blur" }],
            goodsPiStatus: [{ required: true, message: "请选择是否启用", trigger: "change" }],
         },
         //导入表单验证
         importRules: {
            customerId: [{ required: true, message: "请选择制造商", trigger: "change" }],
            multipartFile: [{ required: true, message: "请选择文件", trigger: "change" }],
         },
         //查看模态框开关
         dialogVisibleSee: false,
         seeForm: {
            customer: "",
            goodsPiName: "",
            goodsPiIsLens: "",
            goodsPiSku: "",
            parameters: "",
            goodsPiIsCustomized: "",
            mapList: [],
         },
         //  制造商下拉列表
         manufacturerList: [],
         manufacturerListdata: [],
         //  供应商下拉列表
         supplierList: [],
         //品牌下拉列表
         brandList: [],
         //商品类型列表
         categoryList: [],
         saveBrandList: [],
         //  加工参数列表
         machininList: [],
         //显示编码
         //showCode: false,

         //品名搜索集合
         goodsPiNameList: [],
         //  折射率下拉列表
         productDroplistZsl: [],
         //  材料分类下拉列表
         productDroplistClfl: [],
         //  镜片功能下拉列表
         productDroplistJpgn: [],
         //  光度分类下拉列表
         productDroplistGdfl: [],
         //  球镜区间
         sectionMin: -24,
         sectionmax: 24,
         //柱镜区间
         sectionMinZJ: -8,
         sectionmaxZJ: 8,
         //间隔
         difference: 0.25,
         sectionList: [],
         //  间隔列表
         spanList: ["0.25", "0.5", "0.75", "1", "1.5", "2"],

         //直径区间
         sectionMinDiameter: 50,
         sectionMaxDiameter: 80,
         //直径间隔
         diameterDifference: 10,
         //直径间隔列表
         diameterSpanList: ["10", "20", "30"],
         goodsGs: [
            {
               label: "全部",
               value: ""
            },
            {
               label: "标准厂家",
               value: "1"
            },
            {
               label: "非标供应商",
               value: "0"
            }

         ]
      }
   },
   mounted() {
      //  查询商品类型
      this.getCategoryList()
      //初始化计算表格区域高度
      this.setTableHeight()
      //  初始化表单
      this.searchSubmit()
      //  初始化制造商列表
      this.manufacturer()
      //  初始加工参数列表
      this.machiningInfo()
      //  改变商品code类型
      this.changeProductCodeType()
   },
   methods: {
      _setGoodsType(type) {
         const list = this.categoryList
         try {
            return list[type * 1 - 1]['goodsPcName']
         } catch {
            return '---'
         }
      },

      _setDZP(row) {
         if (row.goodsPiCategoryId == '3') {
            return row.goodsPiIsCustomized == 0 ? '是' : '否'
         }
         return '否'
      },
      // 供应商野码回显
      productCodeReshow() {
         const id = this.saveForm.goodsPiSupplierId
         return this.supplierList.filter(item => item.sysCiId === id)[0]?.sysCiShortName || ""
      },
      //判断权限是否存在
      hasPermission(item) {
         return this.$common.hasPermission(item)
      },
      /****表格高度计算 start********************************************************************************/
      //计算表格区域高度
      setTableHeight() {
         this.$nextTick(() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
            window.onresize = () => {
               return (() => {
                  this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
               })()
            }
         })
      },
      /****表格高度计算 end********************************************************************************/
      //制造商列表
      manufacturer() {
         let user = JSON.parse(sessionStorage.getItem("userInfo"))
         this.searchForm.userid = user.sysUiId
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server
            .getList({ sysCiType: 1, userid: user.sysUiId, isSave: this.isSave })
            .then(res => {
               if (res.ok) {
                  // console.log('制造商列表请求数据', JSON.stringify(res.data))
                  this.manufacturerList = res.data
               }
            })
            .catch(e => {
               console.log(e)
            })
      },
      goodsPII(e) {
         this.manufacturersetting();
      },
      mulsupplierIdList(e) {
         // this.searchForm.supplierIdList = JSON.stringify(this.searchForm.supplierIdList)
      },
      manufacturersetting() {
         let user = JSON.parse(sessionStorage.getItem("userInfo"))
         this.searchForm.userid = user.sysUiId
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server
            .getList({ sysCiType: 2, userid: user.sysUiId, isSave: this.isSave })
            .then(res => {
               if (res.ok) {
                  // console.log('制造商列表请求数据', JSON.stringify(res.data))
                  this.manufacturerListdata = res.data
               }
            })
            .catch(e => {
               console.log(e)
            })
      },
      //加工参数列表
      machiningInfo(formName) {
         console.log("加工参数列表")
         // alert(formName)
         // alert(this.searchForm.goodsPiBrandId)
         if (this[formName].goodsPiBrandId) {
            this.$server
               .getMachiningList({ paramMiBrandId: this[formName].goodsPiBrandId })
               .then(res => {
                  if (res.ok) {
                     this.$set(this.$data, "machininList", res.data)
                     // this.machininList = res.data
                  }
               })
               .catch(e => {
                  console.log(e)
               })
         }
      },
      stopBtn(formName) {
         this.$delete(formName, "paramList")
         formName.paramIds = ""
         this.$server
            .productsaveOrUpdate(formName)
            .then(res => {
               if (res.ok) {
                  this.$message.success(res.msg)
                  this.searchSubmit()
               } else {
                  this.$message.error(res.msg)
               }
               // this.dialogVisibleSave = false
               this.addPage = true
               this.$common.clearFormValue(this.saveForm)
            })
            .catch(e => {
               console.log(e)
            })
      },
      /****搜素区域 start********************************************************************************/
      //搜索展开收起事件
      searchOpen() {
         this.searchShow = !this.searchShow
         this.searchOpenIcon = this.searchShow ? "el-icon-arrow-up" : "el-icon-arrow-down"
      },
      //搜索表单提交
      searchSubmit(flag) {
         if (flag) {
            this.currentPage = 1
            this.searchForm.page = 1
            this.searchForm.pageSize = this.currentPageSize
         }
         this.tableLoading = true
         let user = JSON.parse(sessionStorage.getItem("userInfo"))
         this.searchForm.userid = user.sysUiId
         this.$server
            .productInfoGetByPage(this.searchForm)
            .then(res => {
               this.tableLoading = false
               if (res.ok) {
                  this.tableData = res.data.records
                  this.total = res.data.total
                  console.log(this.tableData)
               }
            })
            .catch(e => {
               this.tableLoading = false
               console.log(e)
            })
      },
      //搜索表单重置
      resetSubmit() {
         this.currentPage = 1
         this.searchForm.page = 1
         this.searchForm.pageSize = this.currentPageSize
         this.$common.clearFormValue(this.searchForm)
         this.$refs.assemblyAutocomplete.clear()
         this.searchSubmit(true)
         this.brandList = []
         this.machininList = []
      },
      //球镜间隔选项 arr:间隔列表 start,最小值 end：最大值
      interval(arr, start, end) {
         let list = arr
         // console.log('==球镜间隔选项===', start != undefined, '==球镜间隔选项===', end, '=====', JSON.stringify(this.saveForm.section))
         if (start != undefined && end != undefined) {
            let num = end - start
            list = arr.filter(function (item) {
               return item <= num
            })
         }

         return list
      },
      // TODO
      //球柱镜区间start:最小值  end:最大值  difference:区间  key:当前条  index:当前条下的第几个 flag:1-球镜2-柱镜3-ADD4-直径  minOrMax:最大值还是最小值
      section(start, end, difference, key, flag, minOrMax) {
         //条判断
         let section = this.saveForm.section
         key = Number(key)
         if (flag == 1) {
            if (section[key].goodsPsSphStart && minOrMax != "start") {
               start = parseFloat(section[key].goodsPsSphStart) + difference
               if (key > 0) {
                  for (let i = 0; i < section.length; i++) {
                     if (start < section[i].goodsPsSphStart) {
                        end = section[i].goodsPsSphStart - difference
                     }
                  }
               }
            } else if (section[key].goodsPsSphEnd && minOrMax != "end") {
               end = section[key].goodsPsSphEnd
               if (key > 0) {
                  for (let i = 0; i < section.length; i++) {
                     if (end > section[i].goodsPsSphEnd) {
                        start = section[i].goodsPsSphEnd + difference
                     }
                  }
               }
            }
         } else if (flag == 2) {
            if (section[key].goodsPsCylStart && minOrMax != "start") {
               start = parseFloat(section[key].goodsPsCylStart) + difference
               if (key > 0) {
                  for (let i = 0; i < section.length; i++) {
                     if (start < section[i].goodsPsCylStart) {
                        end = section[i].goodsPsCylStart - difference
                     }
                  }
               }
            } else if (section[key].goodsPsCylEnd && minOrMax != "end") {
               end = section[key].goodsPsCylEnd - difference
               if (key > 0) {
                  for (let i = 0; i < section.length; i++) {
                     if (end > section[i].goodsPsCylEnd) {
                        start = section[i].goodsPsCylEnd + difference
                     }
                  }
               }
            }
         } else if (flag == 3) {
            if (section[key].goodsPsAddStart && minOrMax != "start") {
               start = section[key].goodsPsAddStart + difference
               if (key > 0) {
                  for (let i = 0; i < section.length; i++) {
                     if (start < section[i].goodsPsAddStart) {
                        end = section[i].goodsPsAddStart - difference
                     }
                  }
               }
            } else if (section[key].goodsPsAddEnd && minOrMax != "end") {
               end = section[key].goodsPsAddEnd - difference
               if (key > 0) {
                  for (let i = 0; i < section.length; i++) {
                     if (end > section[i].goodsPsAddEnd) {
                        start = section[i].goodsPsAddEnd + difference
                     }
                  }
               }
            }
         } else if (flag == 4) {
            if (section[key].goodsPsDiameterStart && minOrMax != "start") {
               start = parseFloat(section[key].goodsPsDiameterStart) + difference
               if (key > 0) {
                  for (let i = 0; i < section.length; i++) {
                     if (start < section[i].goodsPsDiameterStart) {
                        end = section[i].goodsPsDiameterStart - difference
                     }
                  }
               }
            } else if (section[key].goodsPsDiameterEnd && minOrMax != "end") {
               end = section[key].goodsPsDiameterEnd - difference
               if (key > 0) {
                  for (let i = 0; i < section.length; i++) {
                     if (end > section[i].goodsPsDiameterEnd) {
                        start = section[i].goodsPsDiameterEnd + difference
                     }
                  }
               }
            }
         }
         var length = (end - start) / difference + 1
         var step = start - difference

         let list = Array.apply(null, { length: length }).map(function (v, i) {
            step = difference + step
            return step
         })
         let list1 = []
         //行判断
         if (key > 0 && flag == 1) {
            for (let i = 0; i < section.length; i++) {
               if (!section[i].goodsPsSphStart || !section[i].goodsPsSphEnd) continue
               list = list.filter(f => f < section[i].goodsPsSphStart || f >= section[i].goodsPsSphEnd)
            }
         } else if (key > 0 && flag == 2) {
            for (let i = 0; i < section.length; i++) {
               if (!section[i].goodsPsCylStart || !section[i].goodsPsCylEnd) continue
               list = list.filter(f => f < section[i].goodsPsCylStart || f >= section[i].goodsPsCylEnd)
            }
         } else if (key > 0 && flag == 3) {
            for (let i = 0; i < section.length; i++) {
               if (!section[i].goodsPsAddStar || !section[i].goodsPsAddEnd) continue
               list = list.filter(f => f < section[i].goodsPsAddStar || f >= section[i].goodsPsAddEnd)
            }
         } else if (key > 0 && flag == 4) {
            for (let i = 0; i < section.length; i++) {
               if (!section[i].goodsPsDiameterStart || !section[i].goodsPsDiameterEnd) continue
               list = list.filter(f => f < section[i].goodsPsDiameterStart || f >= section[i].goodsPsDiameterEnd)
            }
         }
         return list
      },
      //返回下拉名
      returnName(list, row) {
         for (let e in list) {
            for (let i in list[e]) {
               if (row == list[e][i].goodsPdId) {
                  return list[e][i].goodsPdValue
               }
            }
         }
      },
      //其他下拉列表
      productDroplistByGroup(brandId) {
         console.log("下拉值", brandId)
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server
            .productDroplistByGroup({ goodsPdMod: "3-1", brandId: brandId, isSave: this.isSave })
            .then(res => {
               this.tableLoading = false
               if (res.ok) {
                  for (let name in res.data) {
                     if (name == "折射率") {
                        this.productDroplistZsl = res.data[name]
                     }
                     if (name == "材料分类") {
                        this.productDroplistClfl = res.data[name]
                     }
                     if (name == "设计分类") {
                        this.productDroplistGdfl = res.data[name]
                     }
                     if (name == "镜片功能") {
                        this.productDroplistJpgn = res.data[name]
                     }
                  }
               }
            })
            .catch(e => {
               this.tableLoading = false
               console.log(e)
            })
      },
      /****搜素区域 end********************************************************************************/
      /****保存区域 start********************************************************************************/
      //清空制造商时清空品牌，下拉参数
      clear1() {
         if (!this.saveForm.goodsPiCustomerId) {
            this.saveForm.goodsPiBrandId = ""
            this.saveBrandList = []
            this.saveForm.paramIds = []
            this.saveForm.goodsPiLuminosity = ""
            this.saveForm.goodsPiMaterial = ""
            this.saveForm.goodsPiRef = ""
            this.saveForm.goodsPiFunction = ""
            this.productDroplistZsl = []
            this.productDroplistClfl = []
            this.productDroplistJpgn = []
            this.productDroplistGdfl = []
         }
         if (!this.saveForm.goodsPiBrandId) {
            this.saveForm.paramIds = []
            this.saveForm.goodsPiLuminosity = ""
            this.saveForm.goodsPiMaterial = ""
            this.saveForm.goodsPiRef = ""
            this.saveForm.goodsPiFunction = ""
            this.productDroplistZsl = []
            this.productDroplistClfl = []
            this.productDroplistJpgn = []
            this.productDroplistGdfl = []
         }
      },
      //保存表单
      saveOpen() {
         this.dialogTitle = '新增品种',
            this.isAdd = true;
         const init = {
            goodsPiId: "",
            goodsPiCustomerId: "",
            goodsPiName: "",
            goodsPiIsLens: "",
            goodsPiSku: "",
            paramIds: [],
            goodsPiStatus: 0,
            //goodsPiCode: '',
            goodsPiIsCustomized: "",
            goodsPiLuminosity: "",
            goodsPiMaterial: "",
            goodsPiRef: "",
            goodsPiFunction: "",
            goodsPiJuse: "",
            goodsPiBrandId: "",
            goodsPiIsAgentCode: "1",
            section: [
               {
                  goodsPsId: "",
                  goodsPsSphStart: "",
                  goodsPsSphEnd: "",
                  goodsPsSphSpan: "",
                  goodsPsCylStart: "",
                  goodsPsCylEnd: "",
                  goodsPsCylSpan: "",
                  goodsPsAddStart: "",
                  goodsPsAddEnd: "",
                  goodsPsAddSpan: "",
                  goodsPsDiameterStart: "",
                  goodsPsDiameterEnd: "",
                  goodsPsDiameterSpan: "",
               },
            ],
            productCodeType: 0,
            goodsPiSupplierId: "",
            goodsPiAgentCode: "",
            goodsPiCategoryId: "",
         }
         this.saveForm = init
         // this.dialogVisibleSave = true
         this.addPage = false
         this.saveBrandList = []
         //  初始化其他下拉列表
         //this.productDroplistByGroup();
         //  初始化球柱镜区间列表
         this.sectionList = this.section(this.sectionMin, this.sectionmax)
         this.dialogStatus = false
         this.getCategoryList()
      },
      //导入
      importOpen() {
         this.dialogVisibleImport = true
      },
      //模态框关闭前回调
      handleClose() {
         if (this.$store.state.closeModel) {
            this.$confirm("关闭后数据不会保存,确认关闭？")
               .then(_ => {
                  // this.dialogVisibleSave = false
                  this.addPage = true
                  this.showCode = false
                  this.clearForm("saveForm")
                  console.log(1)
               })
               .catch(_ => { })
         } else {
            // this.dialogVisibleSave = false
            this.addPage = true
            this.clearForm("saveForm")
         }
      },
      //模态框关闭前回调
      importClose() {
         // this.dialogVisibleSave = false
         this.dialogVisibleImport = false
         this.showCode = false
         this.clearForm("importForm")
      },
      changeIsLens() {
         if (this.saveForm.section.length == 0 && this.saveForm.goodsPiCategoryId == 3) {
            this.saveForm.section = [
               {
                  goodsPsId: "",
                  goodsPsSphStart: "",
                  goodsPsSphEnd: "",
                  goodsPsSphSpan: "",
                  goodsPsCylStart: "",
                  goodsPsCylEnd: "",
                  goodsPsCylSpan: "",
                  goodsPsAddStart: "",
                  goodsPsAddEnd: "",
                  goodsPsAddSpan: "",
                  goodsPsDiameterStart: "",
                  goodsPsDiameterEnd: "",
                  goodsPsDiameterSpan: "",
               },
            ]
         }
         this.$refs.saveForm.clearValidate("goodsPiIsLens")
      },
      changeStatus() {
         this.$refs.saveForm.clearValidate("goodsPiStatus")
      },
      _resetPage() {
         this.currentPage = 1,
            this.searchForm.page = 1
      },
      //保存提交
      saveSubmit(formName) {
         this.$refs[formName].validate(valid => {
            if (valid) {
               if (this.saveForm.paramIds) {
                  this.saveForm.paramIds = this.saveForm.paramIds.toString()
               }
               if (this.saveForm.goodsPiCategoryId != '3') {
                  this.saveForm.goodsPiIsCustomized = undefined
               }
               this.$server
                  .productsaveOrUpdate(this.saveForm)
                  .then(res => {
                     if (res.code == '200') {
                        this._resetPage()
                        // this.dialogVisibleSave = false
                        this.addPage = true
                        this.searchSubmit()
                        this.showCode = false
                        this.clearForm("saveForm")
                        this.$common.clearFormValue(this.saveForm)
                        this.saveForm.paramIds = []
                        this.isAdd = false
                     }
                  })
                  .catch(e => {
                     console.log(e)
                  })
            } else {
               console.log("error submit!!")
               return false
            }
         })
         this.brandList = []
      },

      //导入提交
      importSubmit(formName) {
         this.$refs[formName].validate(valid => {
            if (valid) {
               this.$refs.upload.submit()
            } else {
               console.log("error submit!!")
               return false
            }
         })
      },
      importFormSuccess(res) {
         // eslint-disable-next-line no-empty
         if (res.ok) {
            this.$message.success(res.msg)
            this.searchSubmit()
            this.clearForm("importForm")
            // eslint-disable-next-line no-empty
         } else {
            this.$message.warning(res.msg)
            this.searchSubmit()
            this.clearForm("importForm")
         }
         this.dialogVisibleImport = false
      },
      //清空表单
      clearForm(formName) {
         let goodsPiCustomerId = ""
         let goodsPiBrandId = ""
         if (formName == "saveForm") {
            goodsPiCustomerId = this.saveForm.goodsPiCustomerId
            goodsPiBrandId = this.saveForm.goodsPiBrandId
         }
         this.showCode = false
         this.$refs[formName].resetFields()
         if (formName == "saveForm") {
            this.saveForm.goodsPiCustomerId = goodsPiCustomerId
            this.saveForm.goodsPiBrandId = goodsPiBrandId
         }

         this.$nextTick(() => {
            this.$refs[formName].clearValidate()
         })
         this.$refs.upload && this.$refs.upload.clearFiles()
      },
      //编辑模态框开启
      async editOpen(row) {
         console.log('row :>> ', row);
         this.dialogTitle = '编辑品种'
         this.isAdd = false;
         this.isSave = this.isAdd == true ? 0 : 1
         await this.changeProductCodeType()
         this.saveForm = JSON.parse(JSON.stringify(row))
         if (row.goodsPiCustomerId) {
            this.$server.getBrandList({ goodsBiCustomerId: row.goodsPiCustomerId, isSave: this.isSave }).then(res => {
               if (res.ok) {
                  this.saveBrandList = res.data
               }
            })
         }

         if (this.saveForm.goodsPiSku) {
            // this.$set(this.saveForm, 'productCodeType', 0)
            // const flag = this.saveForm.supplierName
            // const id = this.supplierList.filter(item => item.sysCiShortName == flag)
            // this.$set(this.saveForm, 'goodsPiSupplierId', id[0]?.sysCiId)
         } else if (this.saveForm.goodsPiBrandId) {
            // alert(JSON.stringify(this.saveForm))
            this.$set(this.saveForm, 'goodsPiSupplierId', this.saveForm.goodsCustomerId)
            // this.$set(this.saveForm, 'productCodeType', 1)
            // const flag = this.saveForm.supplierName
            // const id = this.supplierList.filter(item => item.sysCiShortName == flag)
            // this.$set(this.saveForm, 'goodsPiSupplierId', id[0]?.sysCiId)
         } else {
            // this.$set(this.saveForm, 'productCodeType', undefined)
         }
         // 获取下拉，加工参数之类的
         // if (this.saveForm.goodsPiBrandId) {
         //   this.productDroplistByGroup(this.saveForm.goodsPiBrandId)
         // }

         /*this.saveOpen();*/
         /*this.$refs.saveForm.clearValidate();*/
         // let timer = setTimeout( () => {
         console.log('this.saveForm.goodsPiCategoryId数据 :>> ', this.saveForm);
         this.addPage = false
         this.dialogStatus = true
         this.getCategoryList()
         // console.log(11111111111)
         // console.log(this.dialogStatus)
         // this.productDroplistByGroup(this.saveForm.goodsPiBrandId)
         // },300)
      },
      //更改商品类型
      async changeProductCodeType(type) {
         // 切换时把字段制空
         if (type == 0) {
            this.$set(this.saveForm, 'goodsPiSupplierId', '')
            // this.$set(this.saveForm, 'goodsPiAgentCode', '')
            this.$set(this.saveForm, 'goodsPiSku', '')
            this.saveForm.goodsPiIsAgentCode = '0';
         } else {
            this.$set(this.saveForm, 'goodsPiSku', '')
            this.saveForm.goodsPiIsAgentCode = '1';
         }
         // this.seachAgentSwitch = true;
         await this.$server
            .getList({ sysCiType: 2 })
            .then(res => {
               if (res.ok) {
                  this.supplierList = res.data
               }
            })
            .catch(e => {
               console.log(e)
            })
      },
      //  保存选择供应商
      saveSupplier() {
         this.seachAgentSwitch = false
      },
      //  清空选择供应商
      clearSupplier() {
         this.saveForm.goodsPiSupplierId = ""
      },
      //  关闭选择供应商弹窗
      outClose() {
         this.saveForm.goodsPiSupplierId = ""
         this.saveForm.goodsPiAgentCode = ""
         this.seachAgentSwitch = false
      },
      //  查询商品类型
      getCategoryList() {
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server
            .getCategoryList({ isSave: this.isSave })
            .then(res => {
               if (res.ok) {
                  this.categoryList = res.data
                  console.log("商品类型", this.categoryList)
               }
            })
            .catch(e => {
               console.log(e)
            })
      },
      /****保存区域 end********************************************************************************/
      /****查看区域 start********************************************************************************/
      seeOpen(row) {
         console.log("编辑框==", JSON.stringify(row.mapList))
         this.seeForm = row
         this.dialogVisibleSee = true
      },
      seeClose() {
         this.dialogVisibleSee = false
      },
      /****查看区域 end********************************************************************************/
      /****删除区域 start********************************************************************************/
      delOpen(row) {
         this.$confirm("确认删除该条数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
         })
            .then(() => {
               console.log("删除", row)
               this.$server
                  .productsaveDelete({ id: row.goodsPiId })
                  .then(res => {
                     if (res.ok) {
                        this.$message({
                           type: "success",
                           message: res.msg,
                        })
                        this.searchSubmit()
                     } else {
                        this.$message.error(res.msg)
                     }
                  })
                  .catch(e => {
                     console.log(e)
                  })
            })
            .catch(() => {
               this.$message({
                  type: "info",
                  message: "已取消删除",
               })
            })
      },
      /****删除区域 end********************************************************************************/
      //获取品牌列表
      changeCustomerType(row) {
         //this.showCode = true
         this.brandList = []
         this.saveBrandList = []
         this.saveForm.goodsPiBrandId = ""
         this.searchForm.goodsPiBrandId = ""
         this.productDroplistZsl = []
         this.productDroplistClfl = []
         this.productDroplistJpgn = []
         this.productDroplistGdfl = []
         this.saveForm.goodsPiLuminosity = ""
         this.saveForm.goodsPiFunction = ""
         this.saveForm.goodsPiMaterial = ""
         this.saveForm.goodsPiRef = ""
         this.saveForm.goodsPiCode = ""
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server
            .getBrandList({ goodsBiCustomerId: row, isSave: this.isSave })
            .then(res => {
               if (res.ok) {
                  console.log("品牌列表", res.data)
                  this.brandList = res.data
                  this.saveBrandList = res.data
               }
            })
            .catch(e => {
               console.log(e)
            })
      },
      //获取品名编码
      changeBrandType(row) {
         this.productDroplistZsl = []
         this.productDroplistClfl = []
         this.productDroplistJpgn = []
         this.productDroplistGdfl = []
         this.saveForm.goodsPiLuminosity = ""
         this.saveForm.goodsPiFunction = ""
         this.saveForm.goodsPiMaterial = ""
         this.saveForm.goodsPiRef = ""
         this.saveForm.goodsPiCode = ""
         this.productDroplistByGroup(row)
         // this.$server.getProductCode({'goodsPiBrandId': row}).then(res => {
         //   console.log('获取品名编码', res)
         //   this.showCode = true
         //   if (res.ok) {
         //     this.saveForm.goodsPiCode = res.msg
         //   }
         // }).catch(e => {
         //   console.log(e)
         // })
      },
      handleSizeChange(val) {
         this.currentPage = 1
         this.currentPageSize = val
         this.searchForm.pageSize = val
         this.searchForm.page = 1
         this.searchSubmit()
      },
      handleCurrentChange(val) {
         this.currentPage = val
         this.searchForm.page = val
         this.searchSubmit()
      },
      //排序
      sortChange(row) {
         console.log("排序排序排序", JSON.stringify(row))
         if (row.prop == "customer") {
            this.searchForm.orderByColumn = "sysUiId"
         } else {
            this.searchForm.orderByColumn = row.prop
         }
         if (row.order == "ascending") {
            this.searchForm.order = "asc"
         } else if (row.order == "descending") {
            this.searchForm.order = "desc"
         } else {
            this.searchForm.order = ""
         }
         this.searchForm.page = 1
         this.searchSubmit()
      },
      onSubmit() {
         console.log("submit!")
      },
      //根据品名查询集合
      goodsPiNameChange() {
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server
            .getGoodsPiNameList({
               goodsPiCustomerId: this.searchForm.goodsPiCustomerId,
               goodsPiName: this.searchForm.goodsPiName,
               goodsPiBrandId: this.searchForm.goodsPiBrandId,
               isSave: this.isSave
            })
            .then(res => {
               if (res.ok) {
                  this.goodsPiNameList = []
                  for (const re of res.data) {
                     this.goodsPiNameList.push({ value: re.goodsPiName })
                  }
                  this.$refs.assemblyAutocomplete.setList(this.goodsPiNameList)
               }
            })
            .catch(e => {
               console.log(e)
            })
      },
      //  退出新增页面
      //返回方法
      back() {
         // if (this.searchForm.goodsPiCustomerId) {
         //    this.changeCustomerType(this.searchForm.goodsPiCustomerId)
         // }
         // this.saveBrandList = []
         // this.manufacturerList = [];
         this.isAdd = false;
         this.clearForm("saveForm")
         this.$common.clearFormValue(this.saveForm)
         this.addPage = true
      },
      //新增区间列
      addlinebtn() {
         let data = {
            goodsPsId: "",
            goodsPsSphStart: "",
            goodsPsSphEnd: "",
            goodsPsSphSpan: "",
            goodsPsCylStart: "",
            goodsPsCylEnd: "",
            goodsPsCylSpan: "",
            goodsPsAddStar: "",
            goodsPsAddEnd: "",
            goodsPsAddSpan: "",
         }
         this.saveForm.section.push(data)
      },
      //删除区间行
      deletelinebtn(row) {
         let deleteCount = this.saveForm.section.indexOf(row)
         this.saveForm.section.splice(deleteCount, 1)
      },
      //格式化膜层内容
      formatmapList(row) {
         let result = "--"
         row.mapList.forEach(item => {
            if (Object.keys(item)[0] == "膜层") {
               console.log("11111", item["膜层"].goodsPdValue)
               result = item["膜层"].goodsPdValue
            }
         })
         return result
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
   display: flex;
   flex-direction: column;
   margin: 0 !important;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-height: calc(100% - 30px);
   max-width: calc(100% - 30px);
}

.productName {
   .search {
      //搜索展开收起按钮位置设置
      .searchOpenBtn {
         float: right;
      }

      //表单每行底部外边距设置
      .el-form-item {
         margin-bottom: 10px;
      }

      .el-select {
         width: 200px;
      }

      .el-input {
         width: 200px;
      }

      //分割线外边距设置
      .el-divider--horizontal {
         margin: 0 0 10px 0;
      }
   }

   .qzaInlin {
      white-space: nowrap;

      .el-select {
         width: 45%;
      }

      .input {
         width: 45%;
      }
   }

   .footbtn {
      margin-left: 7%;
   }

   .width50 {
      width: 50%;
   }

   .el-dialog {
      .el-select {
         width: 100%;
      }
   }

   .div1 {
      box-shadow: 0 0 2px #c0c4cc;
      padding: 20px 0 0 10px;
      margin: 0 0 10px 0;
      border-left: 1px solid rgb(24, 144, 255);
   }
}
.flex_box_form {
   display: flex;
}
</style>
