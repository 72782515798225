<template>
  <div class="assembly-autocomplete">
    <el-autocomplete
        :disabled='disabled'
        :size="$store.state.size"
        v-model="state"
        :fetch-suggestions="querySearchAsync"
        :placeholder="placeholder"
        :clearable="clearable"
        @select="handleSelect"
    ></el-autocomplete>
  </div>
</template>

<script>
export default {
  name: "assembly-autocomplete",
  props:['placeholder','clearable','disabled'],
  watch:{
    state(){
      this.$emit('change', this.state)
    },
  },
  data() {
    return {
      restaurants: [],
      state:'',
      timeout:  null
    };
  },
  methods: {
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 800 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    handleSelect(item) {
      console.log(item);
    },
    clear() {
      this.state = ''
    },
    setList(list){
      this.restaurants = []
      this.restaurants = list
    }
  },
}
</script>

<style lang="scss">
.assembly-autocomplete{

}
</style>